.dashboardSection.support .supportSection {
    padding: 0 3rem;
}

.dashboardSection.support .supportSection p,
.dashboardSection.support .supportSection a {
    font-size: 2.25rem;
    line-height: 3rem;
    color: rgba(255, 255, 255, 0.95);
    font-weight: 300;
    text-decoration: none;
    transform: all 200ms ease-in-out;
    text-align: left;
}

.dashboardSection.support .supportSection a {
    font-weight: 400;
}

.dashboardSection.support .supportSection a:hover {
    color: rgba(255, 255, 255, 0.85);
    text-decoration: underline;
}