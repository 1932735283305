@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');

*,
*::after,
*::before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 62.5%;
    -webkit-tap-highlight-color: transparent;
}

:root {
    --dark: rgb(0, 0, 0);
    --light: rgb(255, 255, 255);
    --bg: rgb(240, 243, 248);
    --nav-bg: rgb(250, 250, 250);
    --shadow-tint: rgba(0, 0, 0, 0.15);
    --shadow-tint-light: rgba(0, 0, 0, 0.1);
    --light-hover: rgb(230, 233, 238);
    --light-active: rgb(209, 222, 255);
    --dark-95: rgba(0, 0, 0, 0.95);
    --dark-85: rgba(0, 0, 0, 0.85);
    --dark-50: rgba(0, 0, 0, 0.5);
    --light-95: rgba(255, 255, 255, 0.95);
    --fill: rgb(0, 0, 0);
    --card-bg: rgba(250, 250, 250, 0.75);
    --dark-dim: rgb(0, 0, 0, 0.075);
    --logo: url("../1.png");
}

[data-theme="dark"] {
    --dark: rgb(255, 255, 255);
    --light: rgb(0, 0, 0);
    --bg: rgb(30, 30, 30);
    --nav-bg: rgb(5, 5, 5);
    --shadow-tint: rgba(255, 255, 255, 0.15);
    --shadow-tint-light: rgba(255, 255, 255, 0.1);
    --light-hover: rgb(40, 40, 40);
    --light-active: rgb(90, 90, 90);
    --dark-95: rgba(255, 255, 255, 0.95);
    --dark-85: rgba(255, 255, 255, 0.85);
    --dark-50: rgba(255, 255, 255, 0.5);
    --light-95: rgba(0, 0, 0, 0.95);
    --fill: rgb(255, 255, 255);
    --card-bg: rgba(25, 25, 25, 1);
    --dark-dim: rgb(255, 255, 255, 0.075);
    --logo: url("../2.png");
}

html {
    font-size: 62.5%;
    font-family: 'Montserrat', sans-serif;
}

body {
    background-color: var(--bg);
}

.navigation {
    min-height: 100vh;
    width: 25vw;
    background-color: var(--nav-bg);
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    box-shadow: 0 0 10px var(--shadow-tint);
    z-index: 1000;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    letter-spacing: 2px;
    margin: 2rem 0;
}

.logo img {
    content: var(--logo);
    height: 8rem;
}

.menu {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;
    gap: 2rem;
}

.menuItem {
    background-color: var(--bg);
    width: 80%;
    display: flex;
    padding-left: 3rem;
    align-items: center;
    border-radius: 5px;
    height: 5rem;
    gap: 2.5rem;
    text-decoration: none;
    transition: all 200ms ease-in-out;
    cursor: pointer;
}

.menuItem:hover {
    background-color: var(--light-hover);
}

.menuItem.active {
    background-color: var(--light-active);
}

.menuItem svg {
    fill: var(--fill);
}

.menuItem p {
    font-size: 1.75rem;
    color: var(--dark-95);
    letter-spacing: 1px;
}

.logout {
    margin-top: auto;
    outline: none;
    border: none;
    cursor: pointer;
}

/* DARK MODE */
.themeSwitchWrapper {
    display: flex;
    align-items: center;
    margin: 2rem auto;
    cursor: pointer;
}

.themeSwitch {
    display: inline-block;
    height: 34px;
    position: relative;
    z-index: 9;
    width: 60px;
    cursor: pointer;
}

.themeSwitch input {
    display: none;
}

.slider {
    background-color: #ddd;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 300ms;
}

.slider:before {
    background-color: var(--dark);
    bottom: 4px;
    content: "";
    height: 26px;
    left: 4px;
    position: absolute;
    transition: 300ms;
    width: 26px;
    z-index: 11;
}

input:checked+.slider {
    background-color: #ccc;
}

input:checked+.slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.sun {
    height: 2rem;
    width: 2rem;
    position: absolute;
    z-index: 10;
    top: 7px;
    left: 33px;
}

.moon {
    height: 3rem;
    width: 3rem;
    position: absolute;
    z-index: 10;
    top: 2px;
    right: 27px;
}

@media screen and (max-width: 925px) {
    .logo {
        font-size: 3rem;
    }

    .menuItem {
        padding-left: clamp(1rem, 8.889vw - 5.222rem, 3rem);
        gap: clamp(1.5rem, 4.444vw - 1.611rem, 2.5rem);
    }

    .menuItem img {
        height: clamp(1.75rem, 3.333vw - 0.583rem, 2.5rem);
        width: clamp(1.75rem, 3.333vw - 0.583rem, 2.5rem);
    }

    .menuItem p {
        font-size: clamp(1.5rem, 1.111vw + 0.722rem, 1.75rem);
    }
}

@media screen and (max-width: 800px) {
    .navigation {
        position: absolute;
        z-index: 100;
        height: 100vh;
        width: 100vw;
        top: -100vh;
        left: 0;
    }

    .navigation.active {
        animation: menuAppear 500ms ease-in-out 0ms 1 forwards;
    }

    .navigation.inactive {
        animation: menuDisappear 500ms ease-in-out 0ms 1 forwards;
    }

    @keyframes menuAppear {
        from {
            top: -100vh;
        }

        to {
            top: 0vh;
        }
    }

    @keyframes menuDisappear {
        from {
            top: 0vh;
        }

        to {
            top: -100vh;
        }
    }

    .menu {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 3rem 0;
        gap: 0;
    }

    .menuItem {
        width: 100%;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 2px var(--shadow-tint);
        height: 8rem;
    }

    .menuItem img {
        display: none;
    }

    .menuItem p {
        font-size: 1.75rem;
        color: var(--dark-95);
        letter-spacing: 1px;
    }

    .logout {
        margin-top: auto;
    }

    .hamburger {
        position: absolute;
        left: 3rem;
        top: 3rem;
        transform: translate(0%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 2.5rem;
        width: 2.5rem;
        z-index: 1000;
    }

    .line {
        background-color: white;
        border-radius: 1em;
        height: 0.2rem;
        width: 2.5rem;
        transition: all 200ms ease-in-out;
    }

    .line.active {
        background-color: var(--dark);
    }

    .line1 {
        transform-origin: 0% 0%;
    }

    .line3 {
        transform-origin: 0% 100%;
    }

    .line1.active {
        transform: rotate(45deg);
    }

    .line2.active {
        opacity: 0;
    }

    .line3.active {
        transform: rotate(-45deg);
    }
}