*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    height: 100vh;
    display: flex;
    font-family: sans-serif;
    background-color: whitesmoke;
    color: black;
}

#root {
    width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
