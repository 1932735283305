.videoSection {
    height: 100vh;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.95);
    overflow-x: hidden;
}

.btn {
    padding: 1rem 1.5rem;
    margin: 2rem 0;
    font-size: 1.25rem !important;
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.95);
    width: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.95);
    border-radius: 5px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    outline: none;
    border: none;
}

.btn:hover {
    background-color: rgba(255, 255, 255, 0.75);
}

.disabledBtn {
    cursor: not-allowed !important;
    background-color: revert !important;   
    border: none;
    font-size: 1.25rem !important;
}

.videoSection section {
    display: none;
    flex-direction: column;
    min-height: 100vh;
    width: 75vw;
    color: rgba(255, 255, 255, 0.95);
}

.videoSection section.active {
    display: flex;
}

.videoSection .header {
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
}

.videoSection section .controls {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2rem;
    background-color: rgba(0, 0, 0, 0.95);
    gap: 2rem;
}

.videoSection section .controls button {
    padding: 1rem 1.5rem;
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.95);
    height: 100%;
    width: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.95);
    border-radius: 5px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    margin: 0;
}

.videoSection section .controls button:hover {
    background-color: rgba(255, 255, 255, 0.75);
}

.videoSection section .header h1 {
    font-size: 3rem;
    letter-spacing: 2px;
    color: rgba(255, 255, 255, 0.95);
}

.videoSection section .header h2 {
    font-size: 3rem;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.85);
}

.videoSection section .videoArea {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.videoSection section .videoPlayer {
    margin: 0 3rem;
    width: 100%;
    height: 70vh;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 1rem rgba(255, 255, 255, 0.5);
    position: relative;
}

.videoSection section .videoInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 clamp(5rem, 4.688vw + 1.250rem, 8rem);
    flex: 1;
}

.videoSection section .videoInfo .videoTitle {
    font-size: 2.5rem;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.95);
}

@media screen and (max-width: 800px) {
    .videoSection section {
        width: 100vw;
        padding: clamp(1rem, 4.706vw - 0.765rem, 3rem) clamp(2rem, 7.059vw - 0.647rem, 5rem);
    }

    .videoSection section .header {
        margin-top: 3rem;
        padding: 0 2rem;
    }

    .videoSection section .header h1 {
        text-align: center;
        font-size: clamp(3rem, 3.077vw + 1.846rem, 4rem);
    }

    .videoSection section .videoPlayer {
        margin: 0 1rem;
        height: 50vh;
    }

    .videoSection section .videoInfo {
        padding: 3rem clamp(2rem, 7.059vw - 0.647rem, 5rem);
        flex-direction: column;
    }

    .videoSection section .videoInfo .videoTitle {
        font-size: 2.25rem;
        text-align: center;
    }
}