.dashboardSection.home .card {
    display: flex;
    align-items: center;
    background-color: var(--card-bg);
    padding: 1rem 3rem;
    box-shadow: 0 0 5px var(--shadow-tint);
    border-radius: 3px;
    margin: 2rem 0;
}

.dashboardSection.home .card img {
    height: 20rem;
    border-radius: 3px;
    box-shadow: 0 0 5px var(--shadow-tint);
}

.dashboardSection.home .card .courseInfo {
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
}

.dashboardSection.home .card .courseInfo h3 {
    margin-bottom: 1rem;
    font-size: 3rem;
    font-weight: 400;
}

.dashboardSection.home .card .courseInfo p {
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 300;
    text-align: left;
    color: var(--dark-50);
}

.dashboardSection.home .card .courseInfo .btn {
    display: inline-block;
    margin-top: 2rem;
    padding: 1rem 2rem;
    background-color: var(--dark);
    color: var(--light);
    border: none;
    border-radius: 5px;
    font-size: 1.75rem;
    font-weight: 300;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    width: max-content;
    margin-left: auto;
    margin-right: 3rem;
    margin-top: 3rem;
    cursor: pointer;
    outline: none;
}

.dashboardSection.home .card .courseInfo .btn:hover {
    transform: scale(1.05);
}

.dashboardSection.home .paymentPrompt {
    font-size: 2rem;
    margin: 10vh 0;
    color: white;
}

.dashboardSection.home .payment {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    color: white;
    padding: 0;
    margin: 0;
    /* color: var(--dark-95); */
    text-transform: none;
    font-size: 2rem;
    letter-spacing: revert;
    text-decoration: underline;
}

.dashboardSection.home .payment:hover {
    color: rgba(255,255,255,0.75);
    background-color: transparent;
}

@media screen and (max-width: 1200px) {
    .dashboardSection.home .card {
        padding: clamp(1.5rem, 3.000vw - 0.600rem, 3rem);
        margin: clamp(1.5rem, 1.000vw + 0.800rem, 2rem) 0;
    }

    .dashboardSection.home .card img {
        height: clamp(15rem, 10.000vw + 8.000rem, 20rem);
    }

    .dashboardSection.home .card .courseInfo {
        padding: 1.5rem 2.5rem;
    }

    .dashboardSection.home .card .courseInfo h3 {
        margin-bottom: clamp(0.5rem, 1.000vw - 0.200rem, 1rem);
        font-size: clamp(2rem, 2.000vw + 0.600rem, 3rem);
    }

    .dashboardSection.home .card .courseInfo p {
        font-size: clamp(1.25rem, 1.000vw + 0.550rem, 1.75rem);
        line-height: clamp(1.5rem, 1.500vw + 0.450rem, 2.25rem);
    }

    .dashboardSection.home .card .courseInfo .btn {
        margin-top: clamp(1.5rem, 1.000vw + 0.800rem, 2rem);
        padding: clamp(0.5rem, 1.000vw - 0.200rem, 1rem) clamp(1.25rem, 1.500vw + 0.200rem, 2rem);
        font-size: clamp(1.25rem, 1.000vw + 0.550rem, 1.75rem);
        margin-right: clamp(2rem, 2.000vw + 0.600rem, 3rem);
    }
}

.popup {
    z-index: 999;
    color: black;
    background-color: rgba(255, 255, 255, 0.85);
    font-size: 1.5rem;
    position: absolute;
    right: 5rem;
    bottom: -7.5rem;
    padding: 1.75rem 3.5rem;
    border-radius: 5px;
    box-shadow: 0 0 10px var(--shadow-tint);
    animation: popupAppear 2500ms forwards;
}

.popup::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    top: 0;
    left: 0;
    transform-origin: top left;
    background-color: rgb(0, 175, 0);
    animation: popupOverline 2500ms forwards;
}

@keyframes popupAppear {
    0% {
        bottom: -5rem;
    }

    20% {
        bottom: 5rem;
    }

    80% {
        bottom: 5rem;
    }

    100% {
        bottom: -5rem;
    }
}

@keyframes popupOverline {
    0% {
        transform: scaleX(0);
    }

    100% {
        transform: scaleX(1);
    }
}

@media screen and (max-width: 550px) {
    .dashboardSection.home .card {
        flex-direction: column;
        padding: 2rem;
    }

    .dashboardSection.home .card img {
        width: 100%;
        height: auto;
    }

    .dashboardSection.home .card .courseInfo {
        padding: 1rem;
        margin-top: 1rem;
    }

    .dashboardSection.home .card .courseInfo h3 {
        margin-bottom: 1.5rem;
        font-size: 2.5rem;
        text-align: center;
    }

    .dashboardSection.home .card .courseInfo p {
        font-size: 1.5rem;
        line-height: 1.75rem;
        text-align: center;
    }

    .dashboardSection.home .card .courseInfo .btn {
        margin: 0 auto;
        margin-top: 2rem;
        padding: 1rem 2rem;
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 650px) {
    .popup {
        width: 90%;
        right: 50%;
        transform: translateX(50%);
    }
}