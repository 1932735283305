.dashboardSection.admin {
    height: 100vh;
    overflow-y: hidden;
}

.dashboardSection.admin .addUser {
    font-size: clamp(1rem, 0.735vw + 0.191rem, 1.25rem);
    background-color: white;
    height: min-content;
    color: black;
    padding: 0.75rem 1.5rem;
    border-radius: 3px;
    text-decoration: none;
    text-transform: none;
    transition: all 150ms ease-in-out;
    margin: 0;
    outline: none;
    border: none;
    cursor: pointer;
}

.dashboardSection.admin table {
    width: 100%;
    background-color: transparent;
    border-collapse: collapse;
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 10px var(--shadow-tint-light);
    background-color: var(--bg);
    margin: 1rem auto;
}

.dashboardSection.admin table thead {
    background-color: var(--dark-95);
    color: var(--light);
    border-radius: 5px 5px 0 0;
}

.dashboardSection.admin table thead,
.dashboardSection.admin table tbody {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.dashboardSection.admin table tbody {
    max-height: 65vh;
    overflow-y: auto;
}

.dashboardSection.admin table tr {
    display: flex;
    width: 100%;
    box-shadow: 0 0 2px var(--shadow-tint);
}

.dashboardSection.admin table th,
.dashboardSection.admin table td {
    font-size: clamp(1.25rem, 0.735vw + 0.441rem, 1.5rem);
    padding: 1rem 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    box-shadow: 0 0 2px var(--shadow-tint);
    transition: all 0.2s ease-in-out;
    word-break: break-word;
    max-width: 25%;
}

.dashboardSection.admin table th {
    padding: 2rem 1.25rem;
}

.dashboardSection.admin table tr:hover {
    background-color: var(--shadow-tint);
}

.dashboardSection.admin table td.links {
    display: flex;
    gap: 2rem;
}

.dashboardSection.admin table td.links button {
    font-size: clamp(1rem, 0.735vw + 0.191rem, 1.25rem);
    background-color: var(--dark-95);
    color: var(--light);
    padding: 0.75rem 1.5rem;
    border-radius: 3px;
    text-decoration: none;
    text-transform: none;
    transition: all 150ms ease-in-out;
    margin: 0;
    outline: none;
    border: none;
    cursor: pointer;
}

.dashboardSection.admin table td.links a:hover {
    transform: scale(1.05);
}

.modal {
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .4s;
    color: black;
}

.modal:target {
    visibility: visible;
    opacity: 1;
}

.modalContent {
    border-radius: 4px;
    position: relative;
    width: 50rem;
    height: 50vh;
    max-width: 90%;
    background: rgba(255, 255, 255, 0.85);
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalContent svg {
    position: absolute;
    top: 2rem;
    right: 2rem;
    transition: all 200ms ease-in-out;
    cursor: pointer;
}

.modalContent svg:hover {
    transform: scale(1.1);
}

.modalContent form {
    margin-top: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 2rem;
    width: 80%;
}

.modalContent h1 {
    font-size: 3rem;
}

.modalContent label {
    color: black;
    font-size: 1.75rem;

}

.modalContent input {
    border: 1px solid black;
    border-radius: 3px;
    width: 100%;
}

.modalContent button {
    outline: none;
    border: 1px solid black;
    background-color: black;
    color: white;
    padding: 1rem 1.75rem;
    font-size: 1.5rem;
    border-radius: 5px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
}

.modalContent button:hover {
    transform: scale(1.025);
}

.modal__footer a {
    text-align: right;
    color: #585858;
}

.modal__footer i {
    text-align: right;
    color: #d02d2c;
}

.modal__close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #585858;
    text-decoration: none;
}

@media screen and (max-width: 1100px) {

    .dashboardSection.admin table th,
    .dashboardSection.admin table td {
        font-size: clamp(1rem, 0.455vw + 0.750rem, 1.25rem);
        padding: clamp(0.75rem, 1.364vw + 0.000rem, 1.5rem);
    }

    .dashboardSection.admin table td.links button {
        font-size: clamp(0.75rem, 1.364vw + 0.000rem, 1.5rem);
        padding: 0.5rem 1.5rem;
    }
}