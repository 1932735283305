*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

.App {
    height: 100vh;
    display: flex;
    font-family: sans-serif;
    background-color: var(--bg);
    color: black;
    overflow-y: hidden;
}