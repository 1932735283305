*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    height: 100vh;
    display: flex;
    font-family: sans-serif;
    background-color: whitesmoke;
    color: black;
}

.container {
    height: 100vh;
    width: 40vw;
    display: flex;
    flex-direction: column;
    height: 80vh;
    width: 40vw;
    padding: 7vh 10vh;
    z-index: 2;
    margin: auto;
    padding: 8vh 12vh;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(3.5px);
    -webkit-backdrop-filter: blur(6.5px);
    border-radius: 1rem;
}

.container h1 {
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 3rem;
    margin-bottom: 25vh;
}

form {
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.formField {
    display: flex;
    flex-direction: column;
}

input {
    position: relative;
    padding: 1rem 0.5rem;
    background-color: transparent;
    font-weight: 200;
    outline: none;
    border: unset;
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.35);
    font-size: 2rem;
    letter-spacing: 1.2px;
    color: rgba(0, 0, 0, 0.75);
}

input:last-of-type {
    margin-bottom: 0rem;
}

.btn {
    display: inline-block;
    text-transform: uppercase;
    margin: 2rem 0;
    padding: 1.2rem;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 0.25em;
    font-size: 1.75rem;
    letter-spacing: 2.5px;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    transition: all 150ms ease-in-out;
}

.btn:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

a {
    color: rgba(0, 0, 0, 0.95);
    transition: all 100ms ease-in-out;
}

a:hover {
    color: rgba(0, 0, 0, 0.65);
}

p {
    text-align: center;
    font-size: 1.5rem;
}

input:focus,
input:valid {
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.5);
}

label span {
    font-size: 1.75rem;
    position: relative;
    bottom: 3.5rem;
    left: 0.5rem;
    color: rgba(0, 0, 0, 0.5);
    transition: all 200ms ease-in-out;
    z-index: -1;
}

input:focus+label span,
input:valid+label span {
    color: rgba(0, 0, 0, 0.95);
    bottom: 7rem;
    left: 0.5rem;
    font-size: 1.8rem;
}

.forgot {
    font-size: 1.5rem;
    text-align: center;
    margin: 1rem 0;
}

.popup {
    z-index: 999;
    color: black;
    background-color: rgba(255, 255, 255, 0.85);
    font-size: 1.5rem;
    position: absolute;
    right: 5rem;
    bottom: -7.5rem;
    padding: 1.75rem 3.5rem;
    border-radius: 5px;
    box-shadow: 0 0 10px var(--shadow-tint);
    animation: popupAppear 2500ms forwards;
}

.popup::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    top: 0;
    left: 0;
    transform-origin: top left;
    background-color: rgb(0, 175, 0);
    animation: popupOverline 2500ms forwards;
}

@keyframes popupAppear {
    0% {
        bottom: -5rem;
    }

    20% {
        bottom: 5rem;
    }

    80% {
        bottom: 5rem;
    }

    100% {
        bottom: -5rem;
    }
}

@keyframes popupOverline {
    0% {
        transform: scaleX(0);
    }

    100% {
        transform: scaleX(1);
    }
}

@media screen and (max-width: 1100px) {
    .container {
            width: 80%;
            padding: 8vh 12.5vw;
        }

    h1 {
        font-size: clamp(2.5rem, 0.690vw + 2.241rem, 3rem);
    }

    input,
    .btn {
        font-size: clamp(1.5rem, 0.690vw + 1.241rem, 2rem);
    }

    label span {
        font-size: clamp(1.7rem, 0.414vw + 1.545rem, 2rem);
        bottom: clamp(3rem, 0.690vw + 2.741rem, 3.5rem);
    }

    input:focus+label span,
    input:valid+label span {
        font-size: clamp(1.8rem, 0.276vw + 1.697rem, 2rem);
        bottom: clamp(6rem, 1.379vw + 5.483rem, 7rem);
    }

}

@media screen and (max-width: 650px) {
    .container {
        width: 90%;
        padding: 6vh 12.5vw;
    }

    .registerForm {
        gap: 2.5rem;
    }

    .popup {
        width: 90%;
        right: 50%;
        transform: translateX(50%);
    }
}