.dashboardSection.settings {
    flex-direction: column;
}

.dashboardSection.dashboardSection.settings .details {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.dashboardSection.settings .details .detailsSection {
    display: flex;
    align-items: center;
    padding: 2rem 5rem;
    border-bottom: 1px solid rgb(255, 255, 255, 0.3);
}

.dashboardSection.settings .details .detailsSection p {
    color: rgba(255, 255, 255, 0.85);
    font-size: 1.75rem;
    font-weight: 600;
}

.dashboardSection.settings .details .detailsSection .emailBox {
    display: flex;
    flex-direction: column;
    margin-left: auto;
}

.dashboardSection.settings .details .detailsSection span.status.verified {
    width: max-content;
    color: green;
    background-color: rgba(0, 128, 0, 0.1);
    padding: 5px;
    font-size: 1rem;
    margin-left: 1rem;
}

.dashboardSection.settings .details .detailsSection span.status.unverified {
    width: max-content;
    color: red;
    background-color: rgba(255, 0, 0, 0.1);
    padding: 5px;
    font-size: 1rem;
    margin-left: 1rem;
}

.dashboardSection.settings .details .detailsSection input {
    color: rgba(255, 255, 255, 0.85);
    font-size: 1.75rem;
    padding: 1rem;
    outline: none;
    border: none;
    border-radius: 5px;
    margin-left: auto;
    margin-right: 3rem;
    background-color: transparent;
}

.dashboardSection.settings .details .detailsSection svg {
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    stroke: white;
}

.dashboardSection.settings .details .detailsSection svg:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.dashboardSection.settings .details .detailsSection .editOptions svg.action {
    display: none;
}

.dashboardSection.settings .details .detailsSection .editOptions.active svg.action {
    display: block;
}

.dashboardSection.settings .details .detailsSection .editOptions svg.trigger {
    display: block;
}

.dashboardSection.settings .details .detailsSection .editOptions.active svg.trigger {
    display: none;
}

.dashboardSection.settings .details .detailsSection .editOptions.active svg.cancel {
    fill: white;
}

.popup {
    z-index: 999;
        color: black;
        background-color: rgba(255, 255, 255, 0.85);
        font-size: 1.5rem;
        position: absolute;
        right: 5rem;
        bottom: -7.5rem;
        padding: 1.75rem 3.5rem;
        border-radius: 5px;
        box-shadow: 0 0 10px var(--shadow-tint);
        animation: popupAppear 2500ms forwards;
}

.popup::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    top: 0;
    left: 0;
    transform-origin: top left;
    background-color: rgb(0, 175, 0);
    animation: popupOverline 2500ms forwards;
}

@keyframes popupAppear {
    0% {
        bottom: -5rem;
    }

    20% {
        bottom: 5rem;
    }

    80% {
        bottom: 5rem;
    }

    100% {
        bottom: -5rem;
    }
}

@keyframes popupOverline {
    0% {
        transform: scaleX(0);
    }

    100% {
        transform: scaleX(1);
    }
}

@media screen and (max-width: 1100px) {
    .dashboardSection.settings .details .detailsSection input {
        font-size: 1.75rem;
        margin-right: clamp(0rem, 10.000vw - 9.000rem, 3rem);
    }
}

@media screen and (max-width: 650px) {
    .dashboardSection.dashboardSection.settings .details {
        width: 100%;
    }

    .dashboardSection.settings .details .detailsSection {
        padding: clamp(1rem, 3.636vw - 0.364rem, 2rem) clamp(1rem, 14.545vw - 4.455rem, 5rem);
        border-bottom: 1px solid rgb(0, 0, 0, 0.075);
        gap: 1rem;
    }

    .dashboardSection.settings .details .detailsSection p {
        font-size: clamp(1.25rem, 1.818vw + 0.568rem, 1.75rem);
    }

    .dashboardSection.settings .details .detailsSection input {
        font-size: clamp(1.25rem, 1.818vw + 0.568rem, 1.75rem);
        padding: clamp(0.5rem, 1.818vw - 0.182rem, 1rem);
    }

    .dashboardSection.settings .details .detailsSection span.status.verified,
    .dashboardSection.settings .details .detailsSection span.status.unverified {
        padding: clamp(0.25rem, 0.909vw - 0.091rem, 0.5rem);
        font-size: clamp(0.75rem, 0.909vw + 0.409rem, 1rem);
        margin-left: clamp(0.75rem, 0.909vw + 0.409rem, 1rem);
    }

    .popup {
        width: 90%;
        right: 50%;
        transform: translateX(50%);
    }
}