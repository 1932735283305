*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

.App {
    height: 100vh;
    display: flex;
    font-family: sans-serif;
    background-color: whitesmoke;
    color: black;
    overflow-y: hidden;
}

::selection {
    background-color: var(--dark-95);
    color: var(--light);
}

/* MAIN DASHBOARD */

.mainDashboard {
    overflow-y: auto;
    overflow-x: hidden;
}

.mainDashboard section {
    flex-direction: column;
    min-height: 100vh;
    width: 75vw;
    color: var(--dark-95);
    padding: 3rem 5rem;
        background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
                url('./tradingbg.png');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            backdrop-filter: blur(10px);
}

/* .mainDashboard section.active {
    display: flex;
} */

.mainDashboard section .header {
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding-left: 10%;
}

.mainDashboard section .header h1 {
    font-size: 4rem;
    letter-spacing: 2px;
    color: white;
}

.mainDashboard section .header h2 {
    font-size: 3rem;
    font-weight: 300;
    color: white;
    font-style: italic;
}

.mainDashboard section .header h2 span {
    font-size: 3rem;
    font-weight: 500;
}

.dashboardSection {
    background-color: var(--light-95);
}

.popup {
    z-index: 999;
    color: black;
    background-color: rgba(255, 255, 255, 0.85);
    font-size: 1.5rem;
    position: absolute;
    right: 5rem;
    bottom: -7.5rem;
    padding: 1.75rem 3.5rem;
    border-radius: 5px;
    box-shadow: 0 0 10px var(--shadow-tint);
    animation: popupAppear 2500ms forwards;
}

.popup::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    top: 0;
    left: 0;
    transform-origin: top left;
    background-color: rgb(0, 175, 0);
    animation: popupOverline 2500ms forwards;
}

@media screen and (max-width: 800px) {
    .mainDashboard section {
        width: 100vw;
        padding: clamp(1rem, 4.706vw - 0.765rem, 3rem) clamp(2rem, 7.059vw - 0.647rem, 5rem);
    }

    .mainDashboard section .header {
        padding: 0;
    }

    .mainDashboard section .header h1 {
        text-align: center;
        font-size: clamp(3rem, 3.077vw + 1.846rem, 4rem);
    }

    .mainDashboard section .header h2 {
        text-align: center;
        font-size: clamp(2rem, 3.077vw + 0.846rem, 3rem);
    }
}

@media screen and (max-width: 650px) {
    .popup {
        width: 90%;
        right: 50%;
        transform: translateX(50%);
    }
}